import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import index from '@/views/product/index.vue'
import mokidc from '@/views/mobile/mindex'
import product from '@/views/product/pach/product'
import introduce from '@/views/product/pach/introduce'
import recruit from '@/views/product/pach/recruit'
import contact from '@/views/mobile/contact'
import message from '@/views/mobile/message'
import website from '@/views/mobile/website'
import produce from '@/views/mobile/produce'
import systemMessage from '@/views/mobile/systemMessage'

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/mokidc',
      component: mokidc,
      name: mokidc,
      children: [
        {
          path: '/mokidc',
          component: website,
          name: 'website'
        },
        {
          path: '/mokidc/message',
          component: message,
          name: 'message'
        },
        {
          path: '/mokidc/produce',
          component: produce
        }
      ]
    },
    {
      path: '/',
      name: 'index',
      component: index,
      children: [
        {
          path: '/',
          component: product,
          name: 'product'
        },
        {
          path: 'introduce',
          component: introduce,
          name: 'introduce'
        },
        {
          path: 'recruit',
          component: recruit,
          name: 'recruit'
        },
        {
          path: 'system-message',
          component: systemMessage,
          name: 'system-message'
        }
      ]
    },
    {
      path: '/contact',
      component: contact,
      name: 'contact'
    }
  ]
})


export default router
