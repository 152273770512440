<template>
  <div class="mindex">
    <div class="logo" @click="tindex">
      <img :src="companyInfo.header_logo" alt="" />
      <p class="company_name">{{ companyInfo.company_name }}</p>
    </div>
    <div class="bar">
      <div
        class="bar-item"
        @click="toProduct"
        :class="index === 1 ? 'color' : ''"
      >
        官网首页
      </div>
      <div
        class="bar-item"
        @click="toIntroduce"
        :class="index === 2 ? 'color' : ''"
      >
        产品介绍
      </div>
      <div
        class="bar-item"
        :class="index === 3 ? 'color' : ''"
        @click="toRecruit"
      >
        招聘信息
      </div>
    </div>
    <div class="main"><router-view></router-view></div>
    <div class="button">
      <div class="loog">
        <img :src="companyInfo.bottom_logo" alt="" />
      </div>
      <div class="company">关于我们 {{ companyInfo.company_name }}</div>
      <div class="mailbox">联系我们{{ companyInfo.contact_us }}</div>
      <div>
        Copyright © {{ companyInfo.duration_time }}
        {{ companyInfo.company_name }} 保留所有权利
        <a
          href="https://beian.miit.gov.cn/"
          style="color: #666666; display: block; margin-top: 15px"
          target="_blank"
          >{{ companyInfo.record_no }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/content.js";
export default {
  data() {
    return {
      index: 1,
      companyInfo: {},
      listQuery: {
        host: "hineyie.com",
        page_type: 1,
      },
    };
  },
  created() {
    this.getPageInfo();
  },

  methods: {
    getPageInfo() {
      if (process.env.NODE_ENV !== "development") {
        this.listQuery.host = window.location.host.replace("www.", "");
      }
      fetchList(this.listQuery)
        .then((result) => {
          if (result.code === 10000) {
            let pageInfo = result.data.list ? result.data.list[0] : {};
            this.pageInfo = {};
            if (pageInfo) {
              this.companyInfo = pageInfo.companymodel;
              console.log(this.companyInfo);
            } else {
              console.log("页面无数据");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    touchstart() {},
    // 产品
    toIntroduce() {
      if (this.index !== 2) {
        this.$router.push("/mokidc/produce");
      }
      this.index = 2;
    },
    // 首页
    toProduct() {
      if (this.index !== 1) {
        this.$router.push("/mokidc");
      }
      this.index = 1;
    },
    // 招聘
    toRecruit() {
      if (this.index !== 3) {
        this.$router.push("/mokidc/message");
      }
      this.index = 3;
    },
    tindex() {
      this.$router.push("/mokidc");
      this.index = 1;
    },
  },
};
</script>

<style>
.mindex {
  width: 100%;
}
.mindex .logo {
  width: 190px;
  height: 35px;
  margin: 0 auto;
  /* margin-top: 30px; */
  display: flex;
}
.mindex .logo img {
  display: block;
  flex: 0 0 35px;
}
.company_name {
  line-height: 35px;
  text-align: center;
}
.mindex {
  width: 100%;
}
.mindex .header {
  width: 100%;
  /* height: 74px; */
}
.mindex .bar {
  margin-top: 20px;
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-around;
  font-size: 10px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444;
  line-height: 14px;
}
.mindex .bar .bar-item {
}
.mindex .button {
  color: #fff;
  padding-top: 20px;
  width: 100%;
  height: 180px;
  background-color: black;
  text-align: center;
  font-size: 8px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 11px;
}
.mindex .loog {
  width: 78px;
  height: 40px;
  margin: 0 auto;
}
.mindex .loog img {
  width: 100%;
  height: 100%;
}
.mindex .company {
  margin-top: 10px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
  letter-spacing: 1px;
}
.mindex .mailbox {
  margin: 20px 0;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
  letter-spacing: 1px;
}
.mindex .color {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 700;
  color: #000000;
}
</style>
