import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
Vue.use(ElementUI)
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.use(Vant)
// import './utils/utils'
Vue.config.productionTip = false


import router from '@/router/router.js'
import 'lib-flexible'
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
