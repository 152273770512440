import request from '@/utils/request.js'

export function fetchList(query) {
  return request({
    url: '/content/web',
    method: 'get',
    params: query
  })
}

export function fetchJobList(query) {
  return request({
    url: '/job/info',
    method: 'get',
    params: query
  })
}