<template>
    <div class="system-contnt">
        <div class="message-box">
            <div class="message-item" v-for="(value, index) in message" :key="index">
                <span class="icon"></span>
                <span style="max-width: 50vw;">{{ value.text }}</span>
                <span>[{{ value.time.getMonth() + '-' + value.time.getDay() }}]</span>
            </div>
        </div>
    </div>
</template>
  
  <script>

  export default {
    data() {
      return {
        time: "2023-12-31",
        message: [
            {
                'title': '道歉声明',
                'text': '我司未经爱棋道(北京)文化传播有限公司许可，在我司运营的‘围棋入门教学’APP中上传了部分爱棋道(北京)文化传播有限公司的教学视频。双方已就相关争议达成和解，我司已对相关视频进行了下架处理，就此对爱棋道(北京)文化传播有限公司深表歉意。',
                'time': '深圳上翼技术有限公司 2024年5月24日',
                'create_time': new Date()
            },
        ]
      };
    },
    created() {
        console.log(this.isDatePassed('2024-05-28'));
    },
    methods: {
        isDatePassed(compareDate) {
            const currentDate = new Date();
            const comparisonDate = new Date(compareDate);
            return currentDate > comparisonDate;
        }
    },
  };
  </script>
  
  <style scoped>
    .system-contnt {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .message-box {
        width: 55vw;
        min-height: calc(100vh - 524px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .message-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .message-item span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .message-item .icon {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-right: 5px;
        background-color: rgb(76, 0, 255);
    }
  </style>
  