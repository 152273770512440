<template>
  <div class="index">
    <header class="header">
      <div class="header-ceter">
        <div class="ceter1" @click="tindex">
          <img
            :src="companyInfo.header_logo"
            alt=""
            style=" width: 30px;
  height: 30px;"
          />
          <p>{{ companyInfo.company_name }}</p>
        </div>
        <div
          class="ceter2"
          @click="toProduct"
          :class="index === 1 ? 'color' : ''"
        >
          官网首页
        </div>
        <div
          class="ceter3"
          @click="toIntroduce"
          :class="index === 2 ? 'color' : ''"
        >
          产品介绍
        </div>
        <div
          class="ceter4"
          :class="index === 3 ? 'color' : ''"
          @click="toRecruit"
          v-if="listQuery.host==='shineyie.com'"
        >
          招聘信息
        </div>
        <div
          class="ceter5"
          :class="index === 4 ? 'color' : ''"
        >
          <img @click="() => dialogVisible = true" src="@/assets/message.png" alt="">
          <span class="icon" v-show="message.length > 0"></span>
          <!-- 官网通知 -->
        </div> 
      </div>
    </header>
    <div class="main">
      <router-view></router-view>
    </div>
    <!-- 底部 -->
    <div class="button">
      <div class="button-content">
        <div class="loog">
          <img :src="companyInfo.bottom_logo" alt="" />
        </div>
        <div class="referral">
          <span><b>关于我们 :</b> {{ companyInfo.about_us }}</span>
          <span><b>联系我们 :</b> {{ companyInfo.contact_us }}</span>
        </div>
        <div class="statement">
          Copyright © {{ companyInfo.duration_time }}
          {{ companyInfo.company_name }} 保留所有权利
          <a href="https://beian.miit.gov.cn/" target="_blank">{{
            companyInfo.record_no
          }}</a>
          <div style="width:300px;margin:0 auto; padding:20px 0;" v-if="listQuery.host === 'xxkuaifeng.cn'">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43010202001546" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
              <img src="http://hsy-ijj-static.ijianji.cn/app-static/2022-08-15/KaNyFDhbCAngvPcSJotQMXe2.png" style="float:left;"/>
              <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;">湘公网安备 43010202001546号</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="55vw"
      :before-close="() => dialogVisible = false">
        <div class="message-box">
            <div class="message-item" @click="toContact(value)" v-for="(value, index) in message" :key="index">
                <span class="icon"></span>
                <span style="max-width: 40vw;min-width: 40vw;">{{ value.title }}</span>
                <span>[{{ value.create_time}}]</span>
            </div>
        </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible2"
      width="55vw"
      :before-close="() => dialogVisible2 = false">
        <div class="message-content">
          <!-- <p class="title">{{ message_data.title }}</p>
          <p style="margin: 30px 0;">{{ message_data.create_time }} 11:37 上翼深圳</p> -->
          <p class="title" style="margin-bottom: 20px;">{{ message_data.title }}</p>
          <p style="text-indent: 2em;">{{ message_data.text }}</p>
          <p style="align-self: flex-end; margin-top: 20px;">深圳上翼技术有限公司</p>
          <p style="align-self: flex-end; margin-top: 20px;  margin-right: 20px;">{{ message_data.create_time }}</p>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { fetchList } from '@/api/content.js'
export default {
  data () {
    return {
      index: 1,
      tableData: [],
      companyInfo: {},
      dialogVisible: false,
      dialogVisible2: false,
      listQuery:{
        host:'shineyie.com',
        page_type:1
      },
      time: "2023-12-31",
      message_data: {},
      message: [
          {
              'title': '道歉声明',
              'text': '我司未经爱棋道(北京)文化传播有限公司许可，在我司运营的‘围棋入门教学’APP中上传了部分爱棋道(北京)文化传播有限公司的教学视频。双方已就相关争议达成和解，我司已对相关视频进行了下架处理，就此对爱棋道(北京)文化传播有限公司深表歉意。',
              'time': '深圳上翼技术有限公司 2024年5月14日',
              'create_time': '2024年5月24日',
              flage: true
          },
      ]
    }
  },
  created () {
    this.getPageInfo()
    if (!this.isDatePassed('2024-06-07')) {
      this.message = [];
    }
  },
  methods: {
    isDatePassed(compareDate) {
      const currentDate = new Date();
      const comparisonDate = new Date(compareDate);
      return currentDate <= comparisonDate;
    },
    getPageInfo () {
      if (process.env.NODE_ENV !== "development") {
        this.listQuery.host = window.location.host.replace("www.", "");
      }
      fetchList(this.listQuery).then(result => {
          if (result.code === 10000) {
            let pageInfo = result.data.list
              ? result.data.list[0]
              : {}
            this.pageInfo = {}
            if (pageInfo) {
              this.companyInfo = pageInfo.companymodel
              document.getElementsByTagName(
                'title'
              )[0].text = this.companyInfo.company_name
              console.log(this.companyInfo)
              console.log(location.hostname.replace('www.', ''))
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 产品
    toIntroduce () {
      if (this.index !== 2) {
        this.$router.push('/introduce')
      }
      this.index = 2
    },
    // 首页
    toProduct () {
      if (this.index !== 1) {
        this.$router.push('/')
      }
      this.index = 1
    },
    // 招聘
    toRecruit () {
      if (this.index !== 3) {
        this.$router.push('/recruit')
      }
      this.index = 3
    },
    toContact (data) {
      this.dialogVisible = false;
      this.dialogVisible2 = true;
      this.message_data = data;
    },
    tindex () {
      this.$router.push('/')
      this.index = 1
    },
  }
}
</script>

<style>
.index {
  width: 100%;
}

.index .header {
  width: 100%;
  height: 74px;
}

.index .header-ceter {
  max-width: 1180px;
  height: 74px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  line-height: 74px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
}

.index .ceter1 {
  width: 60%;
  height: 100%;
  color: #000;
  display: flex;
  align-items: center;
}

.index .ceter1 p {
  cursor: pointer;
}

.index .ceter2 {
  width: 15%;
  height: 100%;
  cursor: pointer;
}

.index .ceter3 {
  width: 15%;
  height: 100%;
  cursor: pointer;
}

.index .ceter4 {
  width: 15%;
  height: 100%;
  cursor: pointer;
}

.index .ceter5 {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.index .ceter5 img {
  width: 20px;
  height: 20px;
}

.index .ceter5 .icon {
  position: absolute;
  top: 0px;
  right: 0px;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 3px;
  background-color: red;
}

.index .color {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
}

.index .button {
  color: #fff;
  padding-top: 80px;
  width: 100%;
  height: 450px;
  background-color: black;
}

.index .button-content {
  max-width: 1180px;
  margin: 0 auto;
}

.index .button-subject {
  width: 100%;
  height: 160px;
  /* margin-top: 50px; */
  display: flex;
}

.index .button-item {
  width: 25%;
}

.index .iconic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.index .specification {
  padding: 20px 0 0 50px;
}

.index .statement {
  text-align: center;
  margin-top: 50px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.index .statement span {
  padding: 15px;
}

.index .statement a {
  color: #fff;
}

.index .loog {
  margin: 0 auto;
  width: 314px;
  height: 160px;
}

.index .loog img {
  width: 100%;
  height: 100%;
}

.index .referral {
  font-size: 12px;
  margin: 40px auto 10px;
  width: 47%;
  display: flex;
  justify-content: space-between;
}

.message-box {
    width: calc(55vw - 40px);
    min-height: calc(100vh - 524px);
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.message-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
    cursor: pointer;
}
.message-item span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.message-item .icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 5px;
    background-color: rgb(76, 0, 255);
}
.message-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.message-content .title {
  font-size: 25px;
  color: #333;
}
</style>
