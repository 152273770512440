<template>
  <!-- 招聘信息 -->
  <div class="recruit" v-if="bleData[0]">
    <div class="content">
      <van-swipe :autoplay="5000" class="content-video">
        <van-swipe-item
          v-for="(item, index) in bleData[0].contents.bannerList"
          :key="index"
        >
          <video
            autoplay
            muted
            loop
            class="img"
            :src="item.link"
            v-if="item.fileType == 2"
          ></video>
          <img v-else :src="item.link" alt="" />
        </van-swipe-item>
      </van-swipe>
      <div class="title">
        {{ bleData[0].contents.companyInfo.title }}
        <div class="title-item">JOIN US</div>
      </div>
      <div class="Introductions">
        <p v-for="(item, index) in text" :key="index">{{ item }}</p>
      </div>
      <div class="emil">
        <p>如有意向可上传简历到HR邮箱</p>
        <p>我们的邮箱：E-mail：{{ bleData[0].companymodel.contact_us }}</p>
      </div>
      <!-- 标题 -->
      <div class="title" v-if="tableData.length !== 0">
        热招职位
        <div class="title-item">HOT JOB</div>
      </div>
      <div class="popular">
        <div
          class="popular-item"
          v-for="(items, index) in tableData"
          :key="index"
        >
          <div class="popular-item-wot"></div>
          <div class="popular-item-title">
            {{ items.kind_name }}
            <div class="e"></div>
          </div>
          <div class="popular-item-content">
            <div
              class="popular-item-bring"
              v-for="(item, indexss) in items.position_info"
              :key="indexss"
              @click="toYanfa(item)"
            >
              <span>{{ item.post_name }}</span>
              <i class="el-icon-arrow-right icons"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 标题 -->
      <div class="title">
        薪酬福利
        <div class="title-item">SALARY AND BENEFITS</div>
      </div>
      <div class="welfare">
        <img
          src="https://www.bestkids.net/sy-web-static/sy-website/assets/aa_xingchoufuli@2x.png"
          alt=""
        />
      </div>
    </div>
    <div class="contact">
      <el-dialog :visible.sync="innerVisible" width="60%">
        <div class="contact-ding">
          <div class="ding-title">
            {{ titel }}
          </div>
          <div class="ding-xia"></div>
          <div class="ding-content">
            <div class="ding-daiyu">
              薪资待遇:<span>{{ xinzhi }}</span>
            </div>
            <div class="responsibility">
              <p class="responsibility-title">岗位职责：</p>
              <!-- <p>{{ zhise }}</p> -->
              <p v-for="(items, index) in zhise" :key="index">{{ items }}</p>
            </div>
            <div class="responsibility">
              <p class="responsibility-title">任职要求：</p>
              <p v-for="(items, index) in yaoqiu" :key="index">{{ items }}</p>
              <!-- <p>{{ yaoqiu }}</p> -->
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { fetchList,fetchJobList } from "@/api/content.js";
export default {
  data() {
    return {
      titel: "",
      bleData: [],
      tableData: [],
      xinzhi: "",
      yaoqiu: [],
      zhise: [],
      clase: "",
      text: [],
      innerVisible: false,
      listQuery: {
        host: "hineyie.com",
        page_type: 3,
      },
    };
  },
  created() {
    if (process.env.NODE_ENV !== "development") {
        this.listQuery.host = window.location.host.replace("www.", "");
      }
    fetchList(this.listQuery)
      .then((result) => {
        if (result.code == 10000) {
          this.bleData = result.data.list;
          console.log(this.bleData, 22);
          var r = /\s+/;
          this.text = this.bleData[0].contents.companyInfo.text.split(r);
          fetchJobList({
            company_id: this.bleData[0].company_id,
          })
            .then((result) => {
              if (result.code == 10000) {
                this.tableData = result.data.list;
              }
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    recruit(item) {
      // console.log(item, 0)
      this.innerVisible = true;
      this.xinzhi = item.salary;
      this.yaoqiu = item.responsibilities.split("；");
      this.zhise = item.requirement.split("；");
      this.titel = item.post_name;
    },
    toYanfa(item) {
      this.recruit(item);
    },
    toYun(item) {
      this.recruit(item);
    },
    toGuan(item) {
      this.recruit(item);
    },
    toSheji(item) {
      this.recruit(item);
    },
  },
};
</script>

<style>
.recruit {
  width: 100%;
}
.recruit .content {
  width: 1180px;
  margin: 0 auto;
}
.recruit .content .content-video {
  width: 100%;
}
.recruit .img {
  width: 100%;
}
.recruit .title {
  width: 100%;
  text-align: center;
  margin-top: 80px;
  height: 140px;
  font-size: 50px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.recruit .title-item {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
}
.recruit .Introductions {
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #000000;
}
.recruit .Introductions p {
  line-height: 60px;
}
.recruit .emil {
  margin: 200px 0;
}
.recruit .emil p {
  text-align: center;
  font-size: 37px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 80px;
}
.recruit .popular {
  width: 100%;
  /* height: 1000px; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  justify-content: space-between;
}
.recruit .popular-item {
  width: 45%;
}
.recruit .popular-item-wot {
  width: 52px;
  height: 52px;
}
.recruit .popular-item-wot img {
  width: 100%;
  height: 100%;
}
.recruit .popular-item-title {
  margin-top: 20px;
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  letter-spacing: 3px;
}
.recruit .popular-item-title .e {
  width: 132px;
  height: 7px;
  background: #e10000;
  border-radius: 9px;
}
.recruit .popular-item-bring {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 100px;
}
.recruit .popular-item-content {
  /* width: 750px; */
  width: 90%;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #666666;
  letter-spacing: 2px;
}
.recruit .popular-item-bring span {
  flex: 1;
  cursor: pointer;
}
.recruit .popular-item-bring .icons {
  width: 40px;
  color: #e10000;
}
.recruit .welfare {
  width: 100%;
  margin-bottom: 40px;
}
.recruit .welfare img {
  width: 100%;
  height: 100%;
}
/*  */
.recruit .contact {
  width: 100%;
}

.recruit .contact-ding {
  width: 100;
  height: 70vh;
}
.recruit .contact-ding .ding-content {
  margin-top: 30px;
  height: 80%;
  overflow-y: scroll;
  width: 100%;
}
.recruit .contact-ding .ding-icon {
  width: 48px;
  height: 48px;
  margin: 0 auto;
}
.recruit .contact-ding .ding-icon img {
  width: 100%;
  height: 100%;
}
.recruit .contact-ding .ding-title {
  width: 100%;
  text-align: center;
  font-size: 48px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 40px;
  letter-spacing: 3px;
}
.recruit .contact-ding .ding-xia {
  margin: 10px auto 0;
  width: 143px;
  height: 7px;
  background-color: #e10000;
}
.recruit .contact-ding .ding-daiyu {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 36px;
  margin-top: 40px;
}
.recruit .contact-ding .ding-daiyu span {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #e10000;
  line-height: 40px;
  margin-left: 30px;
}
.recruit .contact-ding .responsibility {
  margin-top: 35px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 36px;
}
.recruit .contact-ding .responsibility .responsibility-title {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 44px;
  margin-bottom: 20px;
}
.el-dialog__header {
  padding: 0;
}
</style>
