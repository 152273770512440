<template>
  <!-- 首页 -->
  <div class="website" @touchstart="touchStart" v-if="bleData[0]">
    <div class="carousel">
      <van-swipe :autoplay="5000">
        <van-swipe-item
          v-for="(items, index) in bleData[0].contents.bannerList1"
          :key="index"
        >
          <video
            autoplay
            muted
            loop
            id="video"
            poster="../../assets/mobile/Snipaste_2021-02-02_11-28-49.png"
            x5-video-player-type="h5-page"
            playsinline="true"
            class="img"
            :src="items.link"
          ></video>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 标题 -->
    <div class="title">
      公司业务
      <div class="title-item">CORPORATE BUSINESS</div>
    </div>
    <!-- 标题详情 -->
    <div class="title-content">
      <div class="content-item">
        <div class="caption">拍摄美化</div>
        <div class="matte">图片编辑处理全新体验</div>
        <img
          class="content-img"
          src="../../assets/mobile/aa_pg_01@2x.png"
          alt=""
        />
      </div>
      <div class="content-item">
        <div class="caption">办公效率</div>
        <div class="matte">提升团队工作效率</div>
        <img
          class="content-img"
          src="../../assets/mobile/aa_pg_02@2x.png"
          alt=""
        />
      </div>
      <div class="content-item">
        <div class="caption">实用工具</div>
        <div class="matte">好用实用工具这里都有</div>
        <img
          class="content-img"
          src="../../assets/mobile/aa_pg_03@2x.png"
          alt=""
        />
      </div>
    </div>
    <div class="title">
      千万特效 无限创意
      <div class="title-item">
        TEN MILLION SPECIAL EFFECTS UNLIMED CREATIVITY
      </div>
    </div>
    <div class="state">
      <div>视频美化，复古胶片滤镜</div>
      视频画质、色彩韵味，彷彿漫步胶片复兴年代
    </div>
    <div class="carousel">
      <van-swipe :autoplay="5000">
        <van-swipe-item
          v-for="(items, index) in bleData[0].contents.bannerList2"
          :key="index"
        >
          <video
            muted
            autoplay
            loop
            class="img"
            id="video"
            x5-video-player-type="h5-page"
            playsinline="true"
            poster="../../assets/mobile/Snipaste_2021-02-02_11-29-04.png"
            :src="items.link"
          ></video>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="title">
      更多信息
      <div class="title-item">MORE INFOTMATION</div>
    </div>
    <div class="button-img">
      <img :src="bleData[0].contents.bgFile.link" alt="" />
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/content.js";
export default {
  data() {
    return {
      index: 0,
      bleData: [],
      listQuery: {
        host: "hineyie.com",
      },
    };
  },
  created() {
    if (process.env.NODE_ENV !== "development") {
      this.listQuery.host = window.location.host.replace("www.", "");
    }
    fetchList(this.listQuery)
      .then((result) => {
        if (result.code == 10000) {
          for (let index = 0; index < result.data.list.length; index++) {
            if (result.data.list[index].page_type == 1) {
              this.bleData.push(result.data.list[index]);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    touchStart() {
      let myVideo = document.getElementsByTagName("video"); //对应video标签的ID
      // myVideo.pause()
      myVideo.forEach((element) => {
        element.play();
      });
      console.log(myVideo);
    },
  },
};
</script>

<style>
.website {
  width: 100%;
}

.website .carousel {
  width: 100%;
}

.website .carousel video {
  width: 100%;
}

.website .el-carousel__container {
  height: 280px;
}

.website .title {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 700;
  color: #000000;
  line-height: 22px;
  /* margin: 25px 0; */
  margin-bottom: 25px;
}

.website .title-item {
  font-size: 6px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 8px;
}

.website .title-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.website .content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.website .caption {
  /* height: 45px; */
  font-size: 10px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 14px;
}

.website .matte {
  font-size: 7px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-top: 5px;
}

.website .content-img {
  width: 85%;
  /* width: 114px; */
  /* height: 75%; */
  background: #d8d8d8;
  border-radius: 8px;
  margin-top: 10px;
}

.website .state {
  /* height: 100px; */
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #666666;
  line-height: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.website .button-img {
  width: 100%;
  /* margin-bottom: 100px; */
}

.website .button-img img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
