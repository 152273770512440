<template>
  <!--  产品介绍-->
  <div class="introduce" v-if="bleData[0]">
    <div class="content">
      <van-swipe :autoplay="5000">
        <van-swipe-item
          v-for="(items, index) in bleData[0].contents.bannerList"
          :key="index"
        >
          <video
            autoplay
            muted
            loop
            :src="items.link"
            v-if="items.fileType == 2"
          ></video>
          <img :src="items.link" v-else alt="" />
        </van-swipe-item>
      </van-swipe>

      <!-- cms打包后台主体配置开始 -->
      <!-- <div class="config-container">
        <div v-for="(item, index) in datalist" :key="index">
          <div
            class="item"
            v-if="item.swipes && item.swipes.length >= 4 && item.intro"
            style="position: relative"
          >
            <div class="top-con">
              <div class="left-con">
                <img :src="item.icon" class="app-icon" />
                <div class="app-name">{{ item.title }}</div>
              </div>

              <div class="right-con">{{ item.intro }}</div>
            </div>

            <div class="bottom-con">
              <div
                class="items"
                v-for="(item1, index1) in item.swipes"
                :key="index1"
              >
                <img :src="item1" class="imgs" />
              </div>
            </div>

            <div class="downloadbtn">
              <el-button
                type="primary"
                @click="downApp(item.download_url)"
                v-if="item.download_url"
                style="margin: 20px 0; width: 100px"
                >立即下载</el-button
              >
            </div>
          </div>
        </div>
      </div> -->
      <!-- ijj-cms打包后台主体配置结束 -->

      <div
        v-for="(items, index) in bleData[0].contents.introduceList"
        :key="index"
      >
        <div v-if="items.introduceType != 2" style="position: relative">
          <!-- <div class="name">{{ items.name }}</div> -->
          <div><img :src="items.descBgPic.link" alt="" /></div>
          <van-swipe
            :autoplay="5000"
            :style="{
              'background-image': 'url(' + items.carouselInfo.bgFile.link + ')',
              'background-repeat': 'no-repeat',
              'background-size': '100%',
            }"
          >
            <van-swipe-item
              v-for="(item, ine) in items.carouselInfo.list"
              :key="ine"
            >
              <video
                autoplay
                loop
                :src="item.link"
                v-if="item.fileType == 2"
              ></video>
              <div v-else class="carouses">
                <img
                  :src="item.link"
                  alt=""
                  style="{width: 100%,height: 100%}"
                />
              </div>
            </van-swipe-item>
            <div class="downloadbtn">
              <el-button
                type="primary"
                @click="downApp(items.downloadUrl)"
                v-if="items.downloadUrl"
                style="margin: 20px 0; width: 100px"
                >立即下载</el-button
              >
            </div>
          </van-swipe>
        </div>
        <div v-if="items.introduceType == 2" style="position: relative">
          <div v-for="(item, ine) in items.picList" :key="ine">
            <!-- <div class="name">{{ items.name }}</div> -->
            <video
              autoplay
              loop
              :src="item.link"
              v-if="item.fileType == 2"
            ></video>
            <img :src="item.link" alt="" v-else />
            <div class="downloadbtn">
              <el-button
                type="primary"
                @click="downApp(items.downloadUrl)"
                v-if="items.downloadUrl"
                style="margin: 20px 0; width: 100px"
                >立即下载</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品分类 -->
  </div>
</template>

<script>
import { fetchList } from "@/api/content.js";
import { fetchCmslist } from "@/api/content-cms.js";
export default {
  data() {
    return {
      date: "",
      bleData: [],
      listQuery: {
        host: "shineyie.com",
        page_type: 2,
      },
      datalist: [],
    };
  },
  created() {
    this.date = new Date().getTime();
    this.axios();
  },
  methods: {
    downApp(url) {
      window.open(url);
    },
    axios() {
      if (process.env.NODE_ENV !== "development") {
        this.listQuery.host = window.location.host.replace("www.", "");
      }
      fetchList(this.listQuery)
        .then((result) => {
          if (result.code == 10000) {
            this.bleData = result.data.list;
            console.log(this.bleData, 0);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      fetchCmslist({ domain: this.listQuery.host, pageSize:10000 }).then((res) => {
        if (res.code === 10000) {
          this.datalist = res.data.list;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.introduce {
  width: 100%;
}
.introduce .content {
  width: 1180px;
  margin: 0 auto;
}
.introduce .img {
  width: 100%;
  height: 100%;
}
.introduce .carousel-widht {
  width: 100%;
}
.introduce .advertising {
  width: 100%;
  height: 654px;
  margin-top: 110px;
}
.introduce video {
  width: 100%;
  /* height: 124%; */
}
.introduce img {
  width: 100%;
  /* height: 124%; */
}
.introduce .name {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 45px;
  margin-bottom: 80px;
}
.introduce .aijianji {
  width: 100%;
}
.introduce .aijianji .love {
}
.introduce .aijianji img {
  width: 100%;
}
.introduce .viod {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 45px;
  margin-bottom: 80px;
}
.introduce .product-one {
  width: 100%;
  /* height: 1305px; */
  background: url("https://www.bestkids.net/sy-web-static/sy-website/assets/aa_bg_03@2x.png")
    no-repeat;
  background-size: 110%;
  padding-top: 10%;
  padding-left: 20%;
}
.introduce .carouses {
  width: 60%;
  /* height: 1026px; */
  margin-top: 15px;
}

.introduce .product-one .carouses img {
  width: 100%;
  height: 100%;
}
.introduce .loogin {
  width: 100%;
  height: 100px;
  text-align: center;
  margin-top: -254px;
}
/* .introduce .loogin img {
  width: 100px;
  height: 100px;
} */
.introduce .product-tow {
  padding-top: 20px;
  width: 100%;
  margin: 0 auto;
}
.introduce .product-tow .product-tow-title {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 45px;
}
.introduce .product-tow .product-tow-title p {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 25px;
}
.introduce .product-tow-content {
  width: 100%;
  /* height: 1150px; */
}
.introduce .product-tow-content img {
  width: 100%;
  height: 100%;
}
.introduce .chanping {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.introduce .chanping .chanping-item {
  width: 18%;
  height: 233px;
}
.introduce .chanping .chanping-item:nth-child(1) {
  background: url("https://www.bestkids.net/sy-web-static/sy-website/assets/aa_bgcaise_01@2x.png");
}
.introduce .chanping .chanping-item:nth-child(2) {
  background: url("https://www.bestkids.net/sy-web-static/sy-website/assets/aa_bgcaise_02@2x.png");
}
.introduce .chanping .chanping-item:nth-child(3) {
  background: url("https://www.bestkids.net/sy-web-static/sy-website/assets/aa_bgcaise_03@2x.png");
}
.introduce .chanping .chanping-item:nth-child(4) {
  background: url("https://www.bestkids.net/sy-web-static/sy-website/assets/aa_bgcaise_04@2x.png");
}
.introduce .chanping .chanping-item:nth-child(5) {
  background: url("https://www.bestkids.net/sy-web-static/sy-website/assets/aa_bgcaise_05@2x.png");
}
.introduce .chanping .chanping-item .chanping-herder {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 45px;
  text-align: center;

  margin-top: 30px;
}
.introduce .chanping .chanping-item .chanping-ter {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  width: 80%;
  margin: 0 auto;
  background-repeat: no-repeat;
}
.introduce .shige {
  width: 100%;
  height: 100px;
  text-align: center;
  margin-top: 100px;
}
.introduce .shige img {
  width: 100px;
}
.introduce .shige-study {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 45px;
  margin-top: 10px;
}
.introduce .ancient {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 25px;
}
.introduce .dictionary {
  width: 100%;
  /* height: 1965px; */
}
.introduce .dictionary img {
  width: 100%;
  height: 100%;
}
.introduce .weiqi {
  position: relative;
  width: 100%;
  /* height: 1921px; */
  /* margin-top: 200px; */
  margin-bottom: 200px;
}
.introduce .weiqi .weiqi-loogo {
  width: 100px;
  height: 100px;
}
.introduce .weiqi .weiqi-title {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 45px;
}
.introduce .weiqi .weiqi-content {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 25px;
}
.introduce .weiqi img {
  width: 100%;
  height: 100%;
}
.introduce .weiqi video {
  width: 100%;
  height: 100%;
}
.introduce .weiqi .weiqi-top {
  width: 100%;
  text-align: center;
}
.downloadbtn {
  position: absolute;
  bottom: 10px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.config-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .item {
    width: 100%;
    padding: 30px;
    position: relative;
    background: linear-gradient(360deg, #f6f4ff 0%, #edeafe 100%);
    .top-con {
      width: 100%;
      display: flex;
      align-items: center;
      .left-con {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        .app-name {
          font-size: 25px;
          color: #000;
          font-weight: 500;
          margin-top: 5px;
        }
        .app-icon {
          width: 88px;
          height: 88px;
          border-radius: 10px;
        }
      }
      .right-con {
        margin-left: 50px;
        font-size: 22px;
        color: #333;
        font-weight: 500;
        flex: 5;
      }
    }
    .bottom-con {
      display: flex;
      align-items: center;
      margin-top: 30px;
      .items {
        flex: 1;
        margin-right: 15px;
        .imgs {
          width: 100%;
        }
      }
      .items:last-child {
        margin-right: 0px;
      }
    }
  }
}
</style>
