import axios from 'axios'
import { Message } from 'element-ui'
import { generateStr } from '@/utils/index'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API1, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout,
  headers: {
    post: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    put: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    patch: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (config.params) {
      config.params.timestamp = new Date().getTime()
      config.params.nonce = generateStr(32)
    } else {
      config.params = {
        timestamp: new Date().getTime(),
        nonce: generateStr(32)
      }
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 10000) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
