<template>
  <!-- 招聘 -->
  <div class="message" @touchstart="touchStart" v-if="bleData[0]">
    <div
      class="carousel"
      v-for="(item, index) in bleData[0].contents.bannerList"
      :key="index"
    >
      <video
        class="img"
        autoplay
        muted
        loop
        x5-video-player-type="h5-page"
        playsinline="true"
        poster="../../assets/mobile/Snipaste_2021-02-02_11-29-43.png"
        :src="item.link"
      ></video>
    </div>
    <div class="title">
      {{ bleData[0].contents.companyInfo.title }}
      <div class="title-item">JOIN US</div>
    </div>

    <div
      class="Introductions"
      v-html="bleData[0].contents.companyInfo.text"
    ></div>
    <div class="emil">
      <p>如有意向可上传简历到HR邮箱</p>
      <p>我们的邮箱：E-mail：hr@shineyie.com</p>
    </div>
    <div class="title" v-if="tableData.length !== 0">
      热招职位
      <div class="title-item" v-if="tableData.length !== 0">HOT JOB</div>
    </div>
    <div class="popular">
      <div
        class="popular-item"
        v-for="(items, index) in tableData"
        :key="index"
      >
        <div class="popular-item-title">
          {{ items.kind_name }}
          <div class="e"></div>
        </div>
        <div class="popular-item-content">
          <div
            class="popular-item-bring"
            v-for="(item, indexss) in items.position_info"
            :key="indexss"
            @click="toYanfa(item)"
          >
            <span>{{ item.post_name }}</span>
            <i class="el-icon-arrow-right icons"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      薪酬福利
      <div class="title-item">SALARY AND BENEFITS</div>
    </div>
    <div class="produce-tow">
      <img
        src="https://www.bestkids.net/sy-web-static/sy-website/assets/aa_xingchoufuli@2x.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { fetchList, fetchJobList } from "@/api/content.js";
export default {
  data() {
    return {
      bleData: [],
      tableData: [],
      listQuery: {
        host: "hineyie.com",
      },
    };
  },

  created() {
    if (process.env.NODE_ENV !== "development") {
      this.listQuery.host = window.location.host.replace("www.", "");
    }
    fetchList(this.listQuery)
      .then((result) => {
        if (result.code == 10000) {
          for (let index = 0; index < result.data.list.length; index++) {
            if (result.data.list[index].page_type == 3) {
              this.bleData.push(result.data.list[index]);
            }
          }
          fetchJobList({ company_id: this.bleData[0].company_id }).then(
            (result) => {
              if (result.code == 10000) {
                this.tableData = result.data.list;
                console.log(this.tableData);
              }
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    toYanfa(item) {
      this.$router.push(
        "/contact?title=" + item.id + "&company_id=" + item.company_id
      );
    },
    // toYun () {
    //   this.$router.push('/contact?title=' + )
    // },
    // toGuan () {
    //   this.$router.push('/contact?title=' + )
    // },
    // toSheji () {
    //   this.$router.push('/contact?title=' + )
    // },
    touchStart() {
      let myVideo = document.getElementsByTagName("video"); //对应video标签的ID
      // myVideo.pause()
      myVideo.forEach((element) => {
        element.play();
      });
    },
  },
};
</script>

<style>
.message {
  width: 100%;
}
.message .carousel video {
  width: 100%;
}
.message .title {
  width: 100%;
  text-align: center;
  margin: 20px 0;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.message .title-item {
  font-size: 6px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
}
.message .Introductions {
  padding: 10px 30px;
  font-size: 11px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #000000;
}
.message .Introductions p {
  line-height: 20px;
}
.message .emil {
  margin: 20px 0;
}
.message .emil p {
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 18px;
}
.message .popular {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  justify-content: space-between;
  padding: 15px 50px;
}
.message .popular-item {
  width: 100%;
  margin-top: 20px;
}
.message .popular-item-wot {
  width: 20px;
  height: 20px;
}
.message .popular-item-wot img {
  width: 100%;
  height: 100%;
}
.message .popular-item-title {
  margin-top: 5px;
  font-size: 15px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  letter-spacing: 3px;
}
.message .popular-item-title .e {
  width: 30px;
  height: 4px;
  background: #e10000;
  border-radius: 9px;
}
.message .popular-item-bring {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 40px;
}
.message .popular-item-content {
  /* width: 750px; */
  /* width: 90%; */
  font-size: 13px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #666666;
  letter-spacing: 2px;
}
.message .popular-item-bring span {
  flex: 1;
  cursor: pointer;
}
.message .popular-item-bring .icons {
  width: 10px;
  color: #e10000;
}
.message .produce-tow {
  margin: 18px 0;
  width: 100%;
}
.message .produce-tow img {
  width: 100%;
}
</style>
