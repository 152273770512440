<template>
  <!-- 产品 -->
  <div class="produce" @touchstart="touchStart" v-if="bleData[0]">
    <div class="carousel">
      <van-swipe :autoplay="5000">
        <van-swipe-item
          v-for="(items, index) in bleData[0].contents.bannerList"
          :key="index"
        >
          <video
            autoplay
            x5-video-player-type="h5-page"
            playsinline="true"
            muted
            loop
            class="img"
            poster="../../assets/mobile/Snipaste_2021-02-02_11-28-49.png"
            :src="items.link"
          ></video>
          <!-- <img :src="items.link" alt="" /> -->
        </van-swipe-item>
        <!-- <van-swipe-item>
          <video
            class="img"
            muted
            autoplay
            x5-video-player-type="h5-page"
            playsinline="true"
            loop
            src="https://www.bestkids.net/sy-web-static/sy-website/assets/官网所需视频(1)/首页置顶轮播-01.mp4"
          ></video>
        </van-swipe-item> -->
      </van-swipe>
    </div>
    <div class="carousel" v-if="bleData1[0]">
      <video
        autoplay
        class="img"
        muted
        loop
        x5-video-player-type="h5-page"
        playsinline="true"
        poster="../../assets/mobile/Snipaste_2021-02-02_11-29-28.png"
        :src="bleData1[0].carouselInfo.list[0].link"
      ></video>
    </div>
    <div class="aijianji" v-if="bleData1[0]">
      <img :src="bleData1[0].carouselInfo.list[1].link" alt="" />
      <p>{{ bleData1[0].name }}</p>
    </div>
    <div class="viod">
      <p>微视频编辑剪辑制作体验升级</p>
      <p>一切化繁为简</p>
    </div>
    <div class="product-one" v-if="bleData1[1]">
      <div class="carouses">
        <van-swipe class="carouses-const" :autoplay="5000">
          <van-swipe-item
            v-for="(item, index) in bleData1[1].carouselInfo.list"
            :key="index"
          >
            <img :src="`${item.link}`" alt="" class="img" />
          </van-swipe-item>
          <!-- <van-swipe-item>
            <img
              src="../../assets/mobile/aa_appaijianj_02@2x(1).png"
              alt=""
              class="img"
            />
          </van-swipe-item>
          <van-swipe-item>
            <img
              src="../../assets/mobile/aa_appaijianj_02@2x.png"
              alt=""
              class="img"
            />
          </van-swipe-item>
          <van-swipe-item>
            <img
              src="../../assets/mobile/aa_appaijianj_04@2x.png"
              alt=""
              class="img"
            />
          </van-swipe-item> -->
        </van-swipe>
      </div>
    </div>
    <div v-for="(item, index) in bleData2" :key="index">
      <div class="loogin" v-if="bleData2">
        <img :src="item.picList[0].link" alt="" />
      </div>
      <div class="produce-title">
        {{ item.name }}
      </div>
      <div class="produce-tow">
        <!-- <img :src="item.picList[1].link" alt="" /> -->
      </div>
      <!-- <div class="produce-tow">
        <img src="../../assets/mobile/wenzi.png" alt="" />
      </div> -->
    </div>
    <!-- <div class="loogin">
      <img src="../../assets/mobile/shici.png" alt="" />
    </div>
    <div class="produce-title">
      <div>中国古诗词典</div>
      <div>唐诗宋词鉴赏学习</div>
    </div>
    <div class="produce-tow">
      <img src="../../assets/mobile/aa_bg_06@3x@2x.png" alt="" />
    </div>
    <div class="loogin">
      <img src="../../assets/mobile/aa_icon_aijianj备份@2x.png" alt="" />
    </div>
    <div class="produce-title">
      <div>围棋入门教学练习</div>
    </div>
    <div class="produce-tow">
      <img src="../../assets/mobile/aa_bg_07@3x@2x.png" alt="" />
    </div> -->
  </div>
</template>

<script>
import { fetchList } from "@/api/content.js";
export default {
  data() {
    return {
      date: "",
      bleData: [],
      bleData1: [],
      bleData2: [],
      listQuery: {
        host: "hineyie.com",
      },
    };
  },
  created() {
    this.date = new Date().getTime();

    if (process.env.NODE_ENV !== "development") {
      this.listQuery.host = window.location.host.replace("www.", "");
    }
    fetchList(this.listQuery)
      .then((result) => {
        if (result.code == 10000) {
          for (let index = 0; index < result.data.list.length; index++) {
            if (result.data.list[index].page_type == 2) {
              this.bleData.push(result.data.list[index]);
            }
          }
          let excessive = this.bleData[0].contents.introduceList;
          console.log(excessive, 33);
          for (let s = 0; s < excessive.length; s++) {
            if (excessive[s].introduceType == 1) {
              this.bleData1.push(excessive[s]);
            } else if (excessive[s].introduceType == 2) {
              this.bleData2.push(excessive[s]);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    touchStart() {
      let myVideo = document.getElementsByTagName("video"); //对应video标签的ID
      // myVideo.pause()
      myVideo.forEach((element) => {
        element.play();
      });
      console.log(myVideo);
    },
  },
};
</script>

<style>
.produce .carousel {
  width: 100%;
}
.produce .carousel video {
  width: 100%;
}
.produce .el-carousel__container {
  height: 280px;
}
.produce .aijianji {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.produce .aijianji img {
  width: 54px;
  height: 54px;
}
.produce .viod {
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin-top: 20px;
}
.produce .product-one {
  margin-top: 18px;
  width: 100%;
  background: url("../../assets/mobile/aa_bg_03@3x@2x.png") no-repeat;
  background-size: 100%;
  padding-top: 10%;
  padding-left: 5%;
}
.produce .product-one .carouses {
  width: 60%;
}

.produce .product-one .carouses .carouses-const .img {
  width: 100%;
}
.produce .el-carousel__button {
  display: none;
}
.produce .loogin {
  width: 100%;
  height: 54px;
  text-align: center;
  margin-top: 20px;
}
.produce .loogin img {
  width: 54px;
  height: 54px;
}
.produce .produce-title {
  margin-top: 7px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.produce .produce-tow {
  margin: 18px 0;
  width: 100%;
}
.produce .produce-tow img {
  width: 100%;
}
</style>
