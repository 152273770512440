<template>
  <!-- 信息详情 -->
  <div class="contact" v-if="tableData">
    <div class="herder">
      <div></div>
      <div>热招职位</div>
      <div></div>
    </div>
    <div class="contact-ding">
      <div class="ding-title"></div>
      <div class="ding-title">
        {{ tableData.post_name }}
      </div>
      <div class="ding-xia"></div>
      <div class="ding-content">
        <div class="ding-daiyu">
          薪资待遇:<span>{{ tableData.salary }}</span>
        </div>

        <div class="responsibility">
          <p class="responsibility-title">岗位职责：</p>
          <p>{{ tableData.requirement }}</p>
          <!-- <p v-for="(items, index) in zhise" :key="index">{{ items }}</p> -->
        </div>
        <div class="responsibility">
          <p class="responsibility-title">任职要求：</p>
          {{ tableData.responsibilities }}
          <!-- <p v-for="(items, index) in yaoqiu" :key="index">{{ items }}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchJobList } from "@/api/content.js";
export default {
  data () {
    return {
      tableData: {},
    }
  },
  created () {
    this.recruit()
  },
  computed: {},
  methods: {
    recruit () {
      let company_id = this.$route.query.company_id
      let id = this.$route.query.title
      fetchJobList({company_id: company_id})
        .then(result => {
          if (result.code == 10000) {
            let table = result.data.list
            table.forEach(element => {
              element.position_info.forEach(item => {
                if (item.id == id) {
                  this.tableData = item
                  console.log(this.tableData, 555)
                }
              })
            })
          }
        })
    }
  }
}
</script>

<style>
.contact .contact-ding .ding-content {
  width: 100%;
  padding: 10px 30px;
}
.contact .contact-ding .ding-title {
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 21px;
  letter-spacing: 1px;
}
.contact .contact-ding .ding-xia {
  margin: 5px auto 0;
  width: 30px;
  height: 4px;
  background-color: #e10000;
}
.contact .contact-ding .ding-daiyu {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 36px;
  margin-top: 10px;
}
.contact .contact-ding .ding-daiyu span {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #e10000;
  line-height: 18px;
  margin-left: 10px;
}
.contact .contact-ding .responsibility {
  margin-top: 10px;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 24px;
}
.contact .contact-ding .responsibility .responsibility-title {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 21px;
  margin-bottom: 18px;
}
.contact .herder {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 20px 20px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
}
.contact .herder img {
  width: 17px;
  height: 100%;
}
</style>
