<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  created () {
    console.log(this._isMobile())
    if (this._isMobile()) {
      // 手机端
      this.$router.replace('/mokidc')
    } else {
      // pc端
      this.$router.replace('/')
    }
  },
  methods: {
    _isMobile () {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      )
      return flag
    }
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}
.app,
body,
html {
  height: 100%;
  background-color: #fff;
}
.spinner {
  display: none !important;
}
</style>
