<template>
  <!-- 官网首页 -->
  <div class="product">
    <div class="content" v-if="bleData[0]">
      <van-swipe :autoplay="5000">
        <van-swipe-item
          v-for="(items, index) in bleData[0].contents.bannerList1"
          :key="index"
        >
          <video
            autoplay
            muted
            loop
            class="img"
            v-if="items.fileType == 2"
            :src="items.link"
          ></video>
          <img :src="items.link" v-else alt="" />
        </van-swipe-item>
      </van-swipe>
      <!-- 标题 -->
      <div class="title">
        公司业务
        <div class="title-item">CORPORATE BUSINESS</div>
      </div>
      <!-- 标题详情 -->
      <div class="title-content">
        <div class="content-item">
          <div class="caption">拍摄美化</div>
          <div class="matte">图片编辑处理全新体验</div>
          <img
            class="content-img"
            src="https://www.bestkids.net/sy-web-static/sy-website/assets/aa_pg_01@2x.png"
            alt=""
          />
        </div>
        <div class="content-item">
          <div class="caption">办公效率</div>
          <div class="matte">提升团队工作效率</div>
          <img
            class="content-img"
            src="https://www.bestkids.net/sy-web-static/sy-website/assets/aa_pg_02@2x.png"
            alt=""
          />
        </div>
        <div class="content-item">
          <div class="caption">实用工具</div>
          <div class="matte">好用实用工具这里都有</div>
          <img
            class="content-img"
            src="https://www.bestkids.net/sy-web-static/sy-website/assets/aa_pg_03@2x.png"
            alt=""
          />
        </div>
      </div>
      <!-- 标题 -->
      <div class="title">
        千万特效 无限创意
        <div class="title-item">
          TEN MILLION SPECIAL EFFECTS UNLIMED CREATIVITY
        </div>
      </div>
      <div class="state">
        <div>视频美化，复古胶片滤镜</div>
        视频画质、色彩韵味，彷彿漫步胶片复兴年代
      </div>
      <van-swipe :autoplay="5000">
        <van-swipe-item
          v-for="(items, index) in bleData[0].contents.bannerList2"
          :key="index"
        >
          <video
            autoplay
            muted
            loop
            class="img"
            v-if="items.fileType == 2"
            :src="items.link"
          ></video>
          <img :src="items.link" v-else alt="" />
        </van-swipe-item>
      </van-swipe>
      <!-- 标题 -->
      <div class="title">
        更多信息
        <div class="title-item">MORE INFOTMATION</div>
      </div>
      <div class="button-img">
        <img :src="bleData[0].contents.bgFile.link" alt="" />
      </div>
    </div>
    <div v-else>网页走丢了</div>
  </div>
</template>

<script>
import { fetchList } from "@/api/content.js";
export default {
  data() {
    return {
      bleData: [],
      listQuery: {
        host: "shineyie.com",
        page_type: 1,
      },
    };
  },
  created() {
    if (process.env.NODE_ENV !== "development") {
        this.listQuery.host = window.location.host.replace("www.", "");
      }
    fetchList(this.listQuery)
      .then((result) => {
        if (result.code == 10000) {
          this.bleData = result.data.list;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style>
.product .product {
  width: 100%;
}

.product .content {
  width: 1180px;
  margin: 0 auto;
}
.product .content img {
  width: 100%;
}
.product .img {
  width: 100%;
  height: 100%;
}

.product .title {
  width: 100%;
  text-align: center;
  margin-top: 80px;
  height: 140px;
  font-size: 54px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
}

.product .title-item {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
}

.product .title-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.product .content-item {
  display: flex;
  /* width: 20%; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product .caption {
  height: 45px;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 45px;
}

.product .matte {
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 33px;
  margin-top: 20px;
}

.product .content-img {
  width: 85%;
  height: 75%;
  background: #d8d8d8;
  border-radius: 8px;
  margin-top: 50px;
}

.product .state {
  height: 100px;
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 50px;
}

.product .button-img {
  width: 100%;
  margin-bottom: 100px;
}

.product .button-img img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
